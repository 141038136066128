<template>
  <div class="commonCardsStudents" data-testid="common-cards-students" v-if="getIsAdmin">
    <span class="commonCardsStudents__title">{{$t('members.texts.txt78')}}</span>
    <span class="commonCardsStudents__number">{{ value || 0 }}</span>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  computed: {
    ...mapGetters({
      getIsAdmin: "config/getIsAdmin",
    }),
  },
  props: {
    value: {
      type: Number,
      require: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.commonCardsStudents {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  height: 20px;
  color: var(--neutral-gray-500);
  &__number {
    color: var(--fontcolor);
  }
}
</style>
