<template>
  <div class="skeleton">
    <div :class="getMovieCoverMode ? 'skeleton-image-poster' : 'skeleton-image'"></div>
    <div class="skeleton skeleton-card-title"></div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  computed: {
    ...mapGetters({
      getMovieCoverMode: "config/getMovieCoverMode",
    }),
  },
  props: {
    poster: {
      type: Boolean,
    },
  },
};
</script>