<template>
	<div>
		<template v-if="getSettings.home_vertical || type == 'course'">
			<div class="commomCardsMessageUnavailable course">
				<span class="commomCardsMessageUnavailable__icon">
					<IconSax name="info-circle" />
				</span>
				<span class="commomCardsMessageUnavailable__content course">{{ description }}</span>
				<div class="commomCardsMessageUnavailable__button">
					<ButtonPlay
            fontSize="xs"
            size="sm"
						type="normal"
						@click="moreDetails"
						:text="$t('card_course.details')"
					/>
				</div>
			</div>
		</template>

		<template v-else>
			<div
				class="commomCardsMessageUnavailable"
				@click="moreDetails"
			>
				<span class="commomCardsMessageUnavailable__content">{{ description }}</span>
				<TextPlay
					decoration="underline"
					color="var(--maincolor)"
					size="xs"
					:text="$t('card_course.details')"
				/>
			</div>
		</template>
	</div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
	computed: {
		...mapGetters({
			getSettings: "config/getSettings",
		}),
		description() {
			return this.type == "module"
				? this.$t("card_course.unavailable_module")
				: this.$t("card_course.unavailable_course");
		},
	},
	methods: {
		moreDetails() {
			this.$emit("show");
		},
	},
	props: {
		type: {
			type: String,
			required: true,
		},
	},
};
</script>

<style lang="scss" scoped>
.commomCardsMessageUnavailable {
	@include flex-column;
	gap: 5px;
	display: flex;
	position: relative;
	z-index: 2;

	&.course {
		display: flex;
		flex-direction: column;
		align-items: center;
		text-align: center;
		gap: 8px;
	}

	::v-deep svg path {
		fill: #fff;
	}

	&__icon {
		width: 24px;
		widows: 24px;
	}

	&__content {
		@extend .body-regular-12;
		max-width: 124px;

		&.course {
			@extend .body-semibold-16;
			max-width: 172px;
			margin-bottom: 8px;
		}
	}
}
</style>
