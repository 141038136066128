<template>
  <div class="commonCardsProgress" data-testid="progress-classroom" v-if="!getIsAdmin">
    <span class="commonCardsProgress__title"> {{ $t("card_course.progress") }} </span>
    <span class="commonCardsProgress__number">{{ value ? value + '%' :  '0%' }}</span>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  computed: {
    ...mapGetters({
      getShowRating: "config/getShowRating",
      getIsAdmin: "config/getIsAdmin",
    }),
  },
  props: {
    value: {
      type: Number,
      require: true
    },
  },
};
</script>

<style lang="scss" scoped>
.commonCardsProgress {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  height: 20px;
  color: var(--neutral-gray-500);
  &__number {
    color: var(--fontcolor);
  }
}
</style>
