<template>
  <div class="commonCardsMessagesAllowed">
    <div class="commonCardsMessagesAllowed--show" @click="$emit('show')">
      <template>
        <span class="commonCardsMessagesAllowed__icon"
          ><IconSax name="eye"
        /></span>
        <span class="commonCardsMessagesAllowed__content">{{ $t("card_course.title") }}</span>
      </template>
    </div>
    <div
      class="commonCardsMessagesAllowed--edit"
      @click="$emit('edit')"
      v-if="type === 'course' && hasPermission && studentCantView"
    >
      <template>
        <span class="commonCardsMessagesAllowed__icon"
          ><IconSax name="edit"
        /></span>
        <span class="commonCardsMessagesAllowed__content">{{ $t("card_course.edit") }}</span>
      </template>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  computed: {
    ...mapGetters({
      getIsAdmin: "config/getIsAdmin",
      hasPermission: "user/hasPermission",
      studentCantView: "user/studentCantView",
    }),
  },
  props: {
    type: {
      type: String,
    },
  },
};
</script>

<style lang="scss" scoped>
.commonCardsMessagesAllowed {
  @include flex-row;
  justify-content: center;
  align-items: center;
  gap: 15%;
  width: 100%;

  ::v-deep svg path {
    fill: #fff;
  }

  &--show {
    @include flex-column;
    justify-content: center;
    align-items: center;
    gap: 5px;
  }

  &--edit {
    @include flex-column;
    justify-content: center;
    align-items: center;
    gap: 5px;
  }

  &__icon {
    width: 20px;
    widows: 20px;
  }
  &__content {
    @extend .body-semibold-12;
    max-width: 127px;
    text-align: center;
  }
}
</style>
