<template>
  <div>
    <template v-if="type === 'course'">
      <div class="commomCardsMessageBlocked course">
        <span class="commomCardsMessageBlocked__icon">
          <IconSax name="info-circle" />
        </span>
        <span class="commomCardsMessageBlocked__content course"
          >{{ $t("card_course.blocked") }}</span
        >
        <div class="commomCardsMessageBlocked__button">
          <ButtonPlay size="smm " type="normal" @click="moreDetails">
            {{ $t("card_course.details") }}
          </ButtonPlay>
        </div>
      </div>
    </template>

    <template v-else>
      <div class="commomCardsMessageBlocked" @click="moreDetails">
        <span class="commomCardsMessageBlocked__content"
          >{{ $t("card_course.blocked_module") }}</span
        >
      </div>
    </template>
  </div>
</template>

<script>
export default {
  methods: {
    moreDetails() {
      this.$emit("show")
    }
  },
  props: {
    data:  {
      type: Object,
    },
    type: {
      type: String,
      default: "module",
    },
  },
};
</script>

<style lang="scss" scoped>
.commomCardsMessageBlocked {
  @include flex-column;
  justify-content: center;
  align-items: center;
  gap: 5px;
  display: flex;
  position: relative;
  z-index: 2;

  &.course {
    gap: 8px;
  }

  ::v-deep svg path {
    fill: #fff;
  }

  &__icon {
    width: 24px;
    widows: 24px;
  }

  &__content {
    @extend .body-regular-12;
    max-width: 127px;
    text-align: center;

    &.course {
      @extend .body-semibold-16;
      max-width: 172px;
      margin-bottom: 8px;
    }
  }
}
</style>
