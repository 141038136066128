<template>
  <h3 class="commonsCardsTitle" :class="{ blocked: blocked }" @click="!blocked ? $emit('show') : null">
    {{ data.title }}
  </h3>
</template>

<script>
export default {
  computed: {
    blocked() {
      const course = this.data.course.has_access === false || this.data.course.is_liberated === false;
      const module = this.data.is_liberated === false;
      return module || course;
    },
  },
  props: {
    data: {
      type: Object,
      default: () => ({
        has_access: false,
        is_liberated: false,
        title: "",
        course: {
          has_access: false,
          is_liberated: false,
        },
      }),
    },
  },
};
</script>

<style lang="scss" scoped>
.commonsCardsTitle {
    margin-top: 8px;
    cursor: pointer;
    color: var(--fontcolor);
    @extend .body-semibold-14;

    &.blocked {
      cursor: default;
      color: var(--neutral-gray-500);
    }
  }
</style>
